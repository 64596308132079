import {
  IBarcodeImportRequestPayload,
  IBarcodeImportSearchPayload,
  IBarcodeSearch,
  IBarcodeSearchAutoCompleteRequest,
  IDownloadWebclipPayload,
  IExportPaginationParams,
  IPaginationPayload,
  IProduct,
  IProject,
  IProjectLocationPayload,
  IProjectUserGroup,
  IPublishProject,
} from 'smartbarcode-web-core/src/utils/types'
import { axiosInstance, IArchivePayload, IPairRequestBody, IUnpairRequest } from '@/utils/api'

export async function getProjectsList(latestVersionOnly = true, includeDrafts = true) {
  const response = await axiosInstance.get('/barcode/projects', {
    params: { latestVersionOnly, includeDrafts },
  })
  return response?.data
}

export async function getActiveProjectsList(isFollowCurrentUser = true) {
  const response = await axiosInstance.get('/barcode/all-projects', {
    params: { isFollowCurrentUser },
  })
  return response?.data
}

export async function fetchProject(code: string, version?: string) {
  const response = await axiosInstance.get('/barcode/project', {
    params: {
      code,
      ...(version && { version }),
    },
  })
  return response?.data?.project
}

export async function fetchDraftProject(projectDraftId: string) {
  const response = await axiosInstance.get('/barcode/project-draft', {
    params: {
      projectDraftId,
    },
  })
  return response?.data
}

export async function fetchProjectById(id: string, version?: string) {
  const response = await axiosInstance.get('/barcode/project', {
    params: {
      id,
      ...(version && { version }),
    },
  })
  return response?.data
}

export async function exportBarCode(payload: Record<string, unknown>) {
  const result = await axiosInstance.post('/barcode/barcode-export', payload)
  return result?.data
}

export async function exportBarCodeAttchFiles(payload: Record<string, unknown>) {
  const result = await axiosInstance.post('/barcode/barcode-download-file', payload)
  return result?.data
}

export async function deleteDraftProject(projectDraftId: string) {
  const response = await axiosInstance.delete('/barcode/project-draft', {
    data: {
      projectDraftId,
    },
  })

  return response?.data
}

export async function publishDraftProject(
  newObj: IProject,
  permissionGroup: IProjectUserGroup[],
  locations: IProjectLocationPayload[]
) {
  if (newObj.isDraft) {
    const objProject = {
      projectDraftId: newObj.id,
      name: newObj.name,
      allowUnmatchedTracking: newObj.allowUnmatchedTracking,
      showBarcodeSearchPage: newObj.showBarcodeSearchPage,
      showTrackingCountPage: newObj.showTrackingCountPage,
      isAutoRecycleEndTrackingPoint: newObj.isAutoRecycleEndTrackingPoint,
      isRecycleChildren: newObj.isRecycleChildren,
      allowForcePairing: newObj.allowForcePairing,
      googleAnalyticsMeasurementId: newObj.googleAnalyticsMeasurementId,
      barcodeTypes: newObj.barcodeTypes,
      trackPoints: newObj.trackPoints,
      userGroups: permissionGroup,
      locations,
      cameraFeatures: newObj.cameraFeatures,
    } as IPublishProject

    const result = await axiosInstance.put('/barcode/project-draft', objProject)
    return result?.data
  } else {
    const objProject = {
      targetProjectId: newObj?.version ? newObj.id : '',
      name: newObj.name,
      code: newObj.code,
      allowUnmatchedTracking: newObj.allowUnmatchedTracking,
      showBarcodeSearchPage: newObj.showBarcodeSearchPage,
      showTrackingCountPage: newObj.showTrackingCountPage,
      isAutoRecycleEndTrackingPoint: newObj.isAutoRecycleEndTrackingPoint,
      isRecycleChildren: newObj.isRecycleChildren,
      allowForcePairing: newObj.allowForcePairing,
      googleAnalyticsMeasurementId: newObj.googleAnalyticsMeasurementId,
      barcodeTypes: newObj.barcodeTypes,
      trackPoints: newObj.trackPoints,
      userGroups: permissionGroup,
      locations,
      cameraFeatures: newObj.cameraFeatures,
    }

    const result = await axiosInstance.post('/barcode/project-draft', objProject)
    return result?.data
  }
}

export async function publishProject(
  newObj: IProject,
  userGroups: IProjectUserGroup[],
  locations: IProjectLocationPayload[],
  isDraft: boolean,
  isUpdateVersion: boolean,
  isDryRun?: boolean
) {
  const objProject = {
    name: newObj.name,
    showBarcodeSearchPage: newObj.showBarcodeSearchPage,
    showTrackingCountPage: newObj.showTrackingCountPage,
    isAutoRecycleEndTrackingPoint: newObj.isAutoRecycleEndTrackingPoint,
    isRecycleChildren: newObj.isRecycleChildren,
    allowUnmatchedTracking: newObj.allowUnmatchedTracking,
    allowForcePairing: newObj.allowForcePairing,
    googleAnalyticsMeasurementId: newObj.googleAnalyticsMeasurementId,
    barcodeTypes: newObj.barcodeTypes,
    trackPoints: newObj.trackPoints,
    userGroups: userGroups,
    locations,
    cameraFeatures: newObj.cameraFeatures,
    ...(isDryRun !== undefined && { isDryRun }),
  } as IPublishProject

  let projectId = ''
  // Publish draft project is already exist from another project
  if (isDraft) {
    objProject.projectDraftId = newObj.id
    if (newObj.targetProjectId) projectId = newObj.targetProjectId
    else objProject.code = newObj.code
  } else {
    if (newObj?.id) projectId = newObj.id
    else objProject.code = newObj.code
  }

  if (projectId) {
    const data = {
      projectId,
      ...objProject,
      updateVersion: isUpdateVersion,
    }
    const result = await axiosInstance.put('/barcode/project', data)
    return result?.data
  } else {
    const result = await axiosInstance.post('/barcode/project', objProject)
    return result?.data
  }
}

export async function validateProjectUpdate(
  newObj: IProject,
  userGroups: IProjectUserGroup[],
  locations: IProjectLocationPayload[],
  isDraft: boolean
) {
  return await publishProject(newObj, userGroups, locations, isDraft, false, true)
}

/** LOCATION */
export async function getBarcodeAttachedFileList(payload: IPaginationPayload & { isSummarize?: boolean }) {
  const response = await axiosInstance.get('/barcode/barcode-download-files', { params: payload })
  return response?.data
}

export async function getProduct(productId: string) {
  const response = await axiosInstance.get('/barcode/product', {
    params: {
      id: productId,
    },
  })
  return response?.data
}

export async function getProducts() {
  const response = await axiosInstance.get('/barcode/products')

  return response?.data
}

export async function getProductSuppliers() {
  const response = await axiosInstance.get('/barcode/product-suppliers')

  return response?.data
}

export async function addProduct(payload: IProduct) {
  return await axiosInstance.post('/barcode/product', payload)
}

export async function updateProduct(productId: string, isActive: boolean, payload: IProduct) {
  const data = { ...payload, productId, isActive }
  return await axiosInstance.put('/barcode/product', data)
}

export async function getBarcodeSearchAutoComplete(data: IBarcodeSearchAutoCompleteRequest) {
  const response = await axiosInstance.post('/barcode/search/auto-complete', data)
  return response?.data
}

export async function getBarcodeList(data: IBarcodeSearch) {
  const response = await axiosInstance.post('/v2/barcode/search', data)
  return response?.data
}

export async function getCountBarcodeList(data: IBarcodeSearch) {
  const response = await axiosInstance.post('/v2/barcode/count', data)
  return response?.data
}

export async function getBarcode(id: string) {
  const response = await axiosInstance.get('/barcode/barcode', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function getBarcodeByPath(id: string, path: string) {
  const response = await axiosInstance.get('/barcode/linked-barcode', {
    params: {
      id,
      path,
    },
  })
  return response?.data
}

export async function getBarcodeChildren(id: string) {
  const response = await axiosInstance.get('/barcode/children', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function getBarcodeImports(data: IBarcodeImportSearchPayload) {
  const response = await axiosInstance.get('/barcode/barcode-imports', {
    params: {
      projectCode: data.projectCode,
      skip: data.skip,
      count: data.count,
    },
  })
  return response?.data
}

export async function getBarcodeImport(payload: IBarcodeImportRequestPayload & { type: 'success' | 'error' }) {
  const response = await axiosInstance.get('/barcode/barcode-import', { params: payload })
  return response?.data
}

export async function getBarcodeCSVExample(projectId: string, barcodeType: string) {
  const response = await axiosInstance.get('/barcode/barcode-import/file/example', {
    params: {
      projectId,
      barcodeType,
    },
    responseType: 'blob',
  })
  return response?.data
}

export async function bulkPublish(projectId: string, count: number, clientIds: string[]) {
  const response = await axiosInstance.post('/barcode/barcode-import', {
    projectId,
    count,
    clientIds,
  })
  return response?.data
}

export async function uploadBarcodeImportCSV(payload: FormData, uploadProgressCallback: Function) {
  const response = await axiosInstance.post('/barcode/barcode-import/file', payload, {
    onUploadProgress: (event: ProgressEvent) => uploadProgressCallback(event),
  })
  return response?.data
}

export async function getBarcodeImportDetail(id: string) {
  const response = await axiosInstance.get('/barcode/barcode-import/barcodes', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function reconcileBarcode(barcodeId: string, index: number) {
  const response = await axiosInstance.post('barcode/ledger/reconcile', {
    barcodeId,
    index,
  })
  return response?.data
}

export async function getBarcodeLedgerStatus(id: string, index: number) {
  const response = await axiosInstance.get('/barcode/ledger/status', {
    params: {
      id,
      index,
    },
  })
  return response?.data
}

export async function invokeExternalAPI(barcodeId: string) {
  return await axiosInstance.post('/barcode/external-api', { barcodeId }, { responseType: 'blob' })
}

export async function compileMessage(barcodeId: string, template: string) {
  return (await axiosInstance.post('barcode/compile-message', { barcodeId, template }))?.data
}

export async function uploadProjectAttachedFile(payload: unknown) {
  const response = await axiosInstance.post('/barcode/project/file', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return response?.data
}

export async function getProjectAttachedFile(filePath: string) {
  const response = await axiosInstance.get('/barcode/project/file', { params: { filePath } })
  return response?.data
}

export async function requestBarcodeReport(barcodeId: string, code: string) {
  const response = await axiosInstance.post('/barcode/barcode-report', {
    barcodeId,
    reportTemplateCode: code,
  })
  return response?.data
}

export async function getBarcodeExport(dataSearch: IExportPaginationParams) {
  const response = await axiosInstance.get('/barcode/barcode-exports', { params: dataSearch })
  return response?.data
}

export async function downloadWebclip(payload: IDownloadWebclipPayload) {
  const response = await axiosInstance.post('/barcode/webclip-export', payload, { responseType: 'blob' })
  return response
}

export async function updateReferenceFieldBarcode(barcodeId: string) {
  const response = await axiosInstance.get('/barcode/reference-data', {
    params: { barcodeId },
  })
  return response?.data
}

export async function archiveBarcodes(data: IArchivePayload) {
  return (await axiosInstance.post('/barcode/archive', data))?.data
}

export async function getBarcodeArchiveProcessList(data: IExportPaginationParams) {
  return (await axiosInstance.get('/barcode/barcode-archives', { params: data }))?.data
}

export async function pairBarcode(request: IPairRequestBody) {
  const response = await axiosInstance.post('/barcode/pairing', request)
  return response?.data
}
export async function unpairBarcode(request: IUnpairRequest) {
  const response = await axiosInstance.post('/barcode/unpairing', request)
  return response?.data
}

export async function getRecycleHistory(id: string, skip: number, count: number) {
  const response = await axiosInstance.get('/barcode/history', { params: { id, skip, count } })
  return response?.data
}

export async function getStatistics(payload: Record<string, string | string[]>) {
  // process the special case: DUPLICATED KEY in url params
  const searchParams = new URLSearchParams()
  Object.entries(payload).map(([key, value]) => {
    if (typeof value === 'string') {
      searchParams.append(key, value)
    } else {
      ;(value as string[]).forEach((k) => searchParams.append(key, k))
    }
  })

  return (await axiosInstance.get('/statistics/barcode/count', { params: searchParams }))?.data
}
